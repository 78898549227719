<template>
    <div class="bg-white box-shadow border-r px-20 pb-20">
        <div class="d-flex-center px-15 border-bottom">
            <h4 class="mr-auto color-333">{{$t('settings.nav4')}}</h4>
        </div>
        <div v-loading="loading">
            <el-row :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='12'>{{$t('settings.ipAddress')}}</el-col>
                <el-col class="text-right" :span='12'>{{$t('settings.Date_time')}}</el-col>
            </el-row>
            <el-row v-for="(item, index) in record.data" :key="index" :gutter="20" type="flex" justify="center" align="middle" class="market-header px-20 py-15 border-bottom font-14">
                <el-col :span='12'>{{ item.ip }}</el-col>
                <el-col :span='12' class="text-right">{{ item.create_time }}</el-col>
            </el-row>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            record: {
                data: []
            }
        }
    },
    created: function() {
        this.fatchRecord()
    },
    methods: {
        fatchRecord: async function () {
            const { data } = await this.$request.get('user/login_log_pc')
            this.record = data
        }
    }
}
</script>
<style lang="scss" scoped>
//
</style>